<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="card-title pt-3 px-3 d-flex justify-content-between">
            <div class="breadcrumb-left">
              <h4>Sliders</h4>
              <div class="breadcrumb-sub-header">
                <router-link to="/dashboard">Dashboard </router-link>\ Slider
              </div>
            </div>
            <div class="breadcrumb-right">
              <div class="card-toolbar">
                <a
                  @click="createOrUpdate()"
                  class="btn btn-primary font-weight-bolder font-size-sm"
                >
                  <span class="svg-icon svg-icon-md svg-icon-white"></span>
                  Create New
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div
            class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
          >
            <v-col cols="12" md="5">
              <v-text-field
                label="Title"
                v-model="search.title"
                clearable
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" md="5">
              <v-select
                :items="status"
                v-model="search.status"
                label="Status"
                item-text="name"
                item-value="value"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="2">
              <button class="btn btn-block btn-primary" @click="searchSlider">
                Search
              </button>
            </v-col>
          </div>

          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr class="text-left">
                  <th class="px-3">
                    <strong>Title</strong>
                  </th>
                  <th class="px-3"><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
              </thead>
              <tbody>
                <template v-if="sliders.length > 0">
                  <tr v-for="(item, index) in sliders" :key="index">
                    <td class="px-2">
                      <a
                        @click="createOrUpdate(item.id)"
                        class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        <v-avatar class="profile" color="grey" size="80">
                          <v-img
                            v-if="item.image_path"
                            :src="item.image_path.thumb"
                          ></v-img>
                        </v-avatar>
                        <span class="ml-3">{{ item.title }}</span>
                      </a>
                    </td>
                    <td class="px-2">
                      <span
                        class="badge text-lg"
                        v-bind:class="{
                          'badge-success': item.is_active,
                          'badge-danger': !item.is_active
                        }"
                      >
                        {{ item.is_active ? "Active" : "In Active" }}
                      </span>
                    </td>

                    <td class="pr-0 px-2 text-center">
                      <template>
                        <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a
                                @click="createOrUpdate(item.id)"
                                class="navi-link"
                              >
                                <span class="navi-icon">
                                  <v-icon color="blue darken-2"
                                    >fas fa-edit</v-icon
                                  >
                                </span>
                                <span class="navi-text">Edit Slider</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a
                                @click.prevent="deleteSlider(item.id)"
                                class="navi-link"
                              >
                                <span class="navi-icon">
                                  <v-icon color="red">fas fa-trash</v-icon>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="5">
                      <h5>No Data Found</h5>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <b-pagination
              v-if="sliders.length > 0"
              class="float-right mt-7"
              @input="getAllSliders"
              v-model="page"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SliderService from "@/service/cms/slider/SliderService";

const slider = new SliderService();

export default {
  data() {
    return {
      total: null,
      perPage: null,
      page: null,
      sliders: [],
      status: [
        { name: "Active", value: 1 },
        { name: "Inactive", value: 0 }
      ],
      search: {
        title: "",
        status: ""
      }
    };
  },
  methods: {
    createOrUpdate(sliderId) {
      if (sliderId == null)
        this.$router.push({
          name: "slider-create"
        });
      else
        this.$router.push({
          name: "slider-edit",
          params: { sliderId: sliderId }
        });
    },
    getAllSliders() {
      slider
        .paginate(this.search, this.page)
        .then(response => {
          this.sliders = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
        })
        .catch(() => {});
    },
    sortedClass(key) {
      return this.sort.key === key
        ? `sorted ${this.sort.isAsc ? "asc" : "desc"}`
        : "";
    },
    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    searchSlider() {
      this.getAllSliders();
    },
    deleteSlider(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            slider
              .delete(item)
              .then(response => {
                if (response.data.status == "OK") {
                  this.getAllSliders();
                  this.$snotify.success("Slider Deleted Successfully ");
                }
              })
              .catch(() => {
                this.$snotify.error("Oops something went wrong");
              });
          }
        }
      });
    }
  },
  mounted() {
    this.getAllSliders();
  }
};
</script>
